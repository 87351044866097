/**
 * @file 路由配置
 * @update
 */
import React from 'react';
import withScrollTo from '../../components/ScrollTo';
import { Redirect } from 'react-router-dom';
import {
  // 首页
  S_IndexPage,
  // 列表
  S_RouteList,
  // 详情
  S_MainDetail
} from './routes';

import Logins from '../pages/Logins';
import Register from '../pages/Logins/register';
import Baseinfo from '../pages/Personal/baseinfo';
import UpdatePassword from '../pages/Personal/updatePassword';
const mainRoutes = [
  {
    path: '/',
    component: withScrollTo(S_IndexPage),
    exact: true
  },
  {
    path: '/Home',
    component: withScrollTo(S_IndexPage),
    exact: true
  },
  {
    path: '/Survey',
    component: () => <Redirect to="/Survey/Introduction" />,
    exact: true
  },
  {
    path: '/Survey/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 10, grade: 2, uiType: 'text' })),
    exact: true
  },
  {
    path: '/News',
    component: () => <Redirect to="/News/Notice" />,
    exact: true
  },
  {
    path: '/News/YPNews',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'news' })),
    exact: true
  },
  {
    path: '/News/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 10, grade: 2, uiType: 'text' })),
    exact: true
  },
  {
    path: '/Collection',
    component: () => <Redirect to="/Collection/OnlineCol" />,
    exact: true
  },
  {
    path: '/Collection/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 9, grade: 2, uiType: 'img' })),
    exact: true
  },
  {
    path: '/Exhibition',
    component: () => <Redirect to="/Exhibition/CurrentExh" />,
    exact: true
  },

  {
    path: '/Exhibition/DigitalExh',
    component: withScrollTo(S_RouteList({ pageSize: 9, grade: 2, uiType: 'img' })),
    exact: true
  },

  {
    path: '/Exhibition/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'exh' })),
    exact: true
  },
  {
    path: '/Education',
    component: () => <Redirect to="/Education/ActNews" />,
    exact: true
  },
  {
    path: '/Education/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'news' })),
    exact: true
  },
  {
    path: '/YPXD',
    component: () => <Redirect to="/YPXD/Past" />,
    exact: true
  },
  {
    path: '/YPXD/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 10, grade: 2, uiType: 'text' })),
    exact: true
  },
  {
    path: '/PersonalCenter/Logins',
    component: withScrollTo(Logins),
    exact: true
  },
  {
    path: '/PersonalCenter/Register',
    component: withScrollTo(Register),
    exact: true
  },
  {
    path: '/PersonalCenter/Baseinfo',
    component: withScrollTo(Baseinfo),
    exact: true
  },
  {
    path: '/PersonalCenter/UpdatePassword',
    component: withScrollTo(UpdatePassword),
    exact: true
  },
  {
    path: '/:first/:id',
    component: withScrollTo(S_MainDetail),
    exact: true
  },
  {
    path: '/:first/:sec/:id',
    component: withScrollTo(S_MainDetail),
    exact: true
  }
];

export const pcRoutes = mainRoutes.concat();

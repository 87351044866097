/* eslint-disable no-irregular-whitespace */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './index.scss';
import { useHistory, useLocation } from 'react-router';
import Layer from '../../common/Layer';
import { Link } from 'react-router-dom';

const IndexPage = (props) => {
  const [username, setUsename] = useState('');
  const [password, setPassword] = useState('');
  const [resMessage, setResMessage] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [leaveTime, setLeaveTime] = useState(3);
  const location = useLocation();
  const backUrl = location.search && location.search.split('=').reverse()[0];
  let timeout = 0;
  let leave = 3;

  /** 登陆成功 3秒后离开 */
  const handleLeave = () => {
    if (leave === 0) {
      clearTimeout(timeout);
      if (location.search) {
        props.his.push(backUrl);
        return;
      } else {
        props.his.push('/');
      }
    }
    timeout = setTimeout(() => {
      leave = leave - 1;
      setLeaveTime(leave);
      handleLeave();
    }, 1000);
  };

  /** 处理用户名 */
  const handelUsername = (e) => {
    setUsename(e.target.value);
  };
  /** 处理密码 */
  const handelPassword = (e) => {
    setPassword(e.target.value);
  };

  /** 点击事件 */
  const handleClick = () => {
    if (username && password) {
      setResMessage({ code: 200, msg: '登录成功' });

      const token = username + '123';

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('loginName', username);
      handleLeave();

      setSubmitted(true);
    } else {
      setResMessage({ code: 400, msg: '请输入登录信息' });
      setSubmitted(true);
    }
  };

  return (
    <div className="mainform login">
      <div className="box">
        <h3 className="logintitle">用户登录</h3>
        <Layer
          containerStyle={{
            width: 260,
            height: 154
          }}
          width={500}
          visible={submitted}
          onClose={() => {
            setSubmitted(false);
          }}
        >
          {resMessage.code === 200 ? (
            <p>登录成功，{leaveTime}秒后跳转...</p>
          ) : (
            <p>{resMessage.msg}</p>
          )}
        </Layer>
        <ul>
          <li>
            <span className="textimg" />
            <input
              type="text"
              placeholder="请输入手机号"
              id="loginName"
              onChange={handelUsername}
            />
          </li>
          <li>
            <span className="passwordimg" />
            <input type="password" placeholder="请输入密码" id="pwd" onChange={handelPassword} />
          </li>
          <li className="submit">
            <div className="commonbtn" id="btnLogin" onClick={handleClick}>
              提交
            </div>
          </li>
          <li className="to-join">
            <Link className="login-a" to="/PersonalCenter/Register">
              <span>注册</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default function () {
  const his = useHistory();
  return <IndexPage his={his} />;
}

/**
 * @file   http
 * @create wangdekui 2020/09/27
 * @update zhangchen 2020/11/23 添加portalId判断
 */

import { createHttpClient } from '@portal-site/data';

const ENV_API = window.ENV_API;
export const CMSGATEWAY = window.CMSGATEWAY; // 正式
export const FILEGATEWAY = window.FILEGATEWAY;

export const appId = 'adb2818598274a4e9c88857cb359cb90';
const appKey = 'G7PMGM35-MMRBVWRG-7BGVZKIQ-3WSYKPXT'.replace(/-/g, '');

export const http = createHttpClient(ENV_API, appId, appKey);

import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import './index.scss';
import { useRoutesMap } from '@portal-site/core';

const Cont = (props) => {
  const { routesMap, history, goBack, isEn } = props;
  return (
    <ul className="breadcrumb">
      {routesMap?.slice(0, 3)?.map((r) => {
        return (
          r.pathname && (
            <li key={r.channelNo}>
              <Link to={r.path}>{r.pathname}</Link>
              <span>»</span>
            </li>
          )
        );
      })}
      {history.length > 1 && goBack ? (
        <li
          className="goback"
          onClick={() => {
            history.goBack();
          }}
        >
          {isEn ? 'Back' : '返回'}
          <span>»</span>
        </li>
      ) : (
        <li />
      )}
    </ul>
  );
};

function Breadcrumb({ goBack, isEn }) {
  const routesMap = useRoutesMap().flatToObject();
  const location = useLocation();
  const history = useHistory();
  let pathnameArray = location && location.pathname.split('/').filter((item) => item !== '');
  pathnameArray.unshift('');
  let matchedRoutes = [];
  let path = '';
  if (Object.keys(routesMap).length > 0) {
    pathnameArray.forEach((item, index) => {
      if (index === 0) {
        matchedRoutes.push({
          path: '/',
          pathname: isEn ? 'Home' : '首页',
          channelNo: ''
        });
      } else {
        path = path + '/' + item;

        matchedRoutes.push({
          path: path,
          pathname: routesMap && routesMap[`${item}`] && routesMap[`${item}`].name,
          channelNo: item
        });
      }
    });
  }
  return <Cont routesMap={matchedRoutes} history={history} goBack={goBack} isEn={isEn} />;
}

export default Breadcrumb;

/**
 * @file   搜索页面
 * @update zhangchen 2020/11/27 修改搜索bug，改为新闻检索
 * @update zhangchen 2020/11/30 修改搜索范围
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useRoutesMap, useQuery } from '@portal-site/core';
import './style.scss';
import SearchList from '../SearchList';
import { usePage } from '../../../components/Hooks';
import { Pager } from '@portal-site/pager';

const Head = function () {
  return (
    <header className="mainheader">
      <div className="allcenter">
        <Link to="/" className="logo" />
      </div>
    </header>
  );
};

const Search = function ({ setPage, keywords, setKeywords, setSearchwords }) {
  // 设置search
  const { search, pathname } = useLocation();
  const url = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const history = useHistory();

  const handleKeyDown = useCallback(
    (e) => {
      if (e.charCode === 0 && e.keyCode === 13) {
        if (keywords) {
          e.preventDefault();
          setPage(1);
          // setKeywords(e.target.value);
          setSearchwords(keywords);
          url.set('key', keywords);
          history.push({
            pathname: pathname,
            search: `?${url.toString()}`
          });
        }
      }
    },
    [setSearchwords, keywords, setPage, history, pathname, url]
  );
  const handClick = () => {
    if (keywords) {
      setPage(1);
      setSearchwords(keywords);
      url.set('key', keywords);
      history.push({
        pathname: pathname,
        search: `?${url.toString()}`
      });
    }
  };

  return (
    <div className="search-box">
      <input
        className="text"
        type="text"
        placeholder="关键词"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <i className="iconfont icon-sousuo" />
      <button
        className="btn"
        onClick={() => {
          handClick();
        }}
      >
        搜&nbsp;&nbsp;索
      </button>
    </div>
  );
};
const Result = function ({ initPage, setPage, route, searchwords, setCount }) {
  const channelList = route.flatToChannelIdObject();
  const options = useMemo(() => {
    return {
      pagination: {
        size: 10,
        page: initPage
      },
      params: {
        title: searchwords
      },
      resource: 'CN'
    };
  }, [initPage, searchwords]);

  const { response = { records: [] }, loading } = useQuery({
    api: 'querySearchList',
    options
  });

  const { records, page, pages, total } = response;
  useEffect(() => {
    setCount(total);
  }, [setCount, total]);
  return (
    <>
      <SearchList
        list={records}
        loading={loading}
        channelList={channelList}
        setPage={setPage}
        page={page}
        pages={pages}
        searchwords={searchwords}
      />
      <Pager
        containerClassName="mypager"
        hrefCreator={(page) => `?page=${page}`}
        forcePage={page}
        pageCount={pages ? pages : 0}
        onPageChange={(page) => setPage(page)}
      />
    </>
  );
};
function SearchPage() {
  const route = useRoutesMap();
  const { search } = useLocation();
  const [keywords, setKeywords] = useState('');
  const [searchwords, setSearchwords] = useState('');
  const [count, setCount] = useState(0);
  const [initPage, setPage] = usePage();
  useEffect(() => {
    const url = new URLSearchParams(search);
    const k = url.get('key');
    if (k) {
      setKeywords(k);
      setSearchwords(k);
      window.scrollTo(0, 0);
    }
  }, [search]);

  return (
    <div className=" main-body otherpage-body search-page">
      <Head />
      <div className="inner-cont">
        <div className="allcenter">
          <Search
            setPage={setPage}
            keywords={keywords}
            setKeywords={setKeywords}
            setSearchwords={setSearchwords}
          />
          {count > 0 ? (
            <div className="tip">
              共为您找到 <span> {count}</span> 条相关内容
            </div>
          ) : (
            <div className="tip" />
          )}
          {searchwords && (
            <Result
              initPage={initPage}
              setPage={setPage}
              route={route}
              searchwords={searchwords}
              setCount={setCount}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchPage;

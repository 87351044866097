/**
 * @file
 * @create
 * @update
 */

import React, { lazy, Suspense } from 'react';
import Loading from '@/app/components/Loading';

const hoc = function (WrapLazy) {
  return function (props) {
    return (
      <Suspense fallback={<Loading />}>
        <WrapLazy {...props} />
      </Suspense>
    );
  };
};

const hoc2 = function (WrapLazy) {
  return function (props) {
    return function () {
      return (
        <Suspense fallback={<Loading />}>
          <WrapLazy {...props} />
        </Suspense>
      );
    };
  };
};

// 首页
const IndexPage = lazy(() => import('../pages/Home'));
const [S_IndexPage] = [hoc(IndexPage)];

// 其他
const RouteList = lazy(() => import('../common/List/RouteList'));
const [S_RouteList] = [hoc2(RouteList)];

// 详情
const MainDetail = lazy(() => import('../common/MainDetail'));
const [S_MainDetail] = [hoc(MainDetail)];

export {
  // 首页
  S_IndexPage,
  S_RouteList,
  // 详情
  S_MainDetail
};

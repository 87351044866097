/**
 * @file   数据读取
 * @create
 */
import {
  listFilter,
  detailFilter,
  fileFilter,
  exhListFilter,
  exhBaseListFilter,
  exhDetailFilter,
  colListFilter,
  colDetailFilter,
  actListFilter,
  actDetailFilter,
  exhBaseDetailFilter,
  exhibitionTypeFilter,
  searchListFilter,
  dictionaryTypeFilter,
  leaveWordListFilter,
  labelSearchFilter,
  questionListFilter,
  questionDetailFilter
} from './filter';
import { CMSGATEWAY, FILEGATEWAY } from './http';

const SEARCHGATEWAY = 'sw-es';
// const SEARCHGATEWAY1 = 'sw-cms-cloud';

function dataProvider(http) {
  const baseProvider = {
    // 文章
    async queryArchivesById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryArchivesById/${id}`);
      const data = res.data.data;
      return {
        records: detailFilter(data)
      };
    },

    async queryArchivesByNo(no) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryArchivesByNo/${no}`);
      const data = res.data.data ? res.data.data : {};
      return {
        records: detailFilter(data)
      };
    },
    async queryArchivesList(options = { pagination: {}, resource: '', params: {} }) {
      const { pagination, params, resource } = options;
      if (!resource)
        return {
          records: [],
          pages: 0,
          page: 0,
          total: 0,
          $$rawData: {}
        };
      const res = await http.post(`/${CMSGATEWAY}/api/queryArchivesList`, {
        entity: {
          channelNo: resource,
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: listFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryArchivesListByMulNo(options = { pagination: {}, resource: '', params: {} }) {
      const { pagination, params, resource } = options;
      if (!resource)
        return {
          records: [],
          pages: 0,
          page: 0,
          total: 0,
          $$rawData: {}
        };
      const res = await http.post(`/${CMSGATEWAY}/api/queryArchivesListByMulNo`, {
        entity: {
          channelNo: resource,
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: listFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 常展
    async queryExhibitionBaseList(options = { pagination: {}, params: {} }) {
      const { pagination, params } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitionBaseList`, {
        entity: {
          languageType: params.languageType ? params.languageType : 'CN',
          // portalId: 'unset',
          linkUrl: params.linkUrl
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: exhBaseListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryExhibitionBaseById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitionBaseById/${id}`);
      const data = res.data.data;
      return {
        records: exhBaseDetailFilter(data),
        $$rawData: data
      };
    },
    // 临展
    async queryExhibitionTempList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitionTempList`, {
        entity: {
          isRecommend: params.isRecommend,
          languageType: params.languageType ? params.languageType : 'CN',
          linkUrl: params.linkUrl,
          extension1: params.extension1
        },
        param: {
          type: params.type, // 1：正在进行  2：已结束
          pageSize: pagination.size,
          pageNum: pagination.page,
          str: params.str
        }
      });
      const data = res.data.data;
      return {
        records: exhListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryExhibitionTempById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitionTempById/${id}`);
      const data = res.data.data;
      return {
        records: exhDetailFilter(data),
        $$rawData: data
      };
    },
    // 藏品总目列表
    async queryCulGeneralPageList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryCulGeneralPageList`, {
        entity: {
          totalNumber: params.totalNumber,
          culName: params.culName,
          culType: params.culType,
          age: params.age
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: data.records,
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 展品列表
    async queryExhibitList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitList`, {
        entity: {
          exhibitType: params.type,
          exhibitName: params.name,
          exhibitLevel: params.level,
          boolean1: params.boolean1,
          isTreasure: params.isTreasure,
          isRecommend: params.isRecommend,
          generalType: params.dictionary1, // dictionary1
          languageType: params.languageType ? params.languageType : 'CN',
          isOwn: params.isOwn,
          age: params.age
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 展品详情
    async queryExhibitById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitById/${id}`);
      const data = res.data.data;
      return {
        records: colDetailFilter(data),
        $$rawData: data
      };
    },
    // 关联展品
    async queryExhibitListByTemp(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitListByTemp`, {
        entity: {
          groupId: params.groupId
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 获取单元
    async queryExhibitUnitListByBase(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`${CMSGATEWAY}/api/queryExhibitUnitListByBase`, {
        entity: {
          groupId: params.groupId
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: data.records,
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 获取展品
    async queryExhibitListByUnit(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`${CMSGATEWAY}/api/queryExhibitListByUnit`, {
        entity: {
          unitId: params.unitId,
          languageType: params.languageType ? params.languageType : 'CN'
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 展品类型
    async queryExhibitTypeList() {
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitTypeList`, {
        entity: {},
        param: {
          pageSize: 0,
          pageNum: 0
        }
      });
      const data = res.data.data;
      return {
        records: exhibitionTypeFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 字典查询
    async queryDictionary(options = { prefix: '' }) {
      const { prefix } = options;
      const res = await http.post(
        `/${CMSGATEWAY}/common/queryDictionaryList?systemCode=bwg-guangxi-minzu&prefix=${prefix}`,
        {}
      );
      const data = res.data.data;
      return {
        records: dictionaryTypeFilter(data),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 活动
    async queryActivityListByType(
      options = { pagination: {}, activityType: '', params: { entityParams: {}, params: {} } }
    ) {
      const { pagination, params, activityType } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryActivityListByType`, {
        entity: {
          activityType: activityType,
          ...params.entityParams
        },
        param: {
          // type: params.param.type, 正在1,回顾2
          pageSize: pagination.size,
          pageNum: pagination.page,
          ...params.params
        }
      });
      const data = res.data.data;
      return {
        records: actListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryActivityById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryActivityById/${id}`);
      const data = res.data.data;
      return {
        records: actDetailFilter(data),
        $$rawData: data
      };
    },
    // 附件
    async queryFileList(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.groupId) return result;
      const { pagination, params } = options;

      const res = await http.post(`/${FILEGATEWAY}/refFileObject/queryPageList`, {
        entity: {
          objectId: params.groupId, // 关联id
          code: params.code ? params.code : '' // 文件类型
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: fileFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 全文检索
    async querySearchList(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0
      };
      if (!options.params.title) return result;
      const { pagination, params } = options;

      const res = await http.post(`/${CMSGATEWAY}/api/querySearchList`, {
        entity: {
          title: params.title ? params.title : '', // 文件类型
          channelNo: 'CN'
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: searchListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total
      };
    },
    // 全文检索
    /*     async searchAll(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.keywords) return result;

      const { pagination, params } = options;

      const res = await http.post(`/${SEARCHGATEWAY}/cmsSearch`, {
        clusterName: 'cms',
        searchValue: params.keywords,
        pageNum: pagination.page,
        pageSize: pagination.size,
        searchMap: {
          equalsMap: `{"portalId": "4624e0f04007978da7e4a04eba2ce137" }`,
          multiMap: `{"channelNo": "${params.allchannel}" }`
        }
      });
      const data = res.data.data;
      return {
        records: searchListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    }, */
    // 标签关联
    async labelSearch(options = { pagination: {}, params: {}, searchMap: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.label) return result;

      const { pagination, params, searchMap } = options;

      const res = await http.post(`/${SEARCHGATEWAY}/labelSearch`, {
        clusterName: 'cms',
        searchKey: 'label',
        pageNum: pagination.page,
        pageSize: pagination.size,
        searchValue: params.label,
        // searchValue: '8c39fa87340bb91f24cdf51c2dc47ea3',
        // searchMap: {
        //    likeMap: '{"portalId":"2341ijij234q_zb_portal"}'
        // }
        searchMap
      });
      const data = res.data.data;
      return {
        records: labelSearchFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    }
  };
  const enhanceProvider = {
    async queryColDetail(options = { pagination: {}, params: {} }) {
      const { pagination, params } = options;
      const a = {
        pagination,
        params: {
          groupId: params.groupId,
          code: 'CMS_EXHIBIT_INFO_DISPLAY'
        }
      };

      const b = params.id;
      return Promise.all([baseProvider.queryFileList(a), baseProvider.queryExhibitById(b)]);
    },

    /**
     * @kind javaapi
     * @description 留言列表
     * @param {Object} options
     */
    async queryMessageList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryMessageList`, {
        entity: {
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: leaveWordListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 添加留言
     * @param {Object} options
     */
    async addMessage(options) {
      const { content, name, phoneNumber, email, type, source } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/addMessage`, {
        content,
        name,
        phoneNumber,
        email,
        type,
        // isAnonymous,
        source,
        orgId: '04a711b4622f469682182e649dee68a8'
      });
      const data = res.data;
      return {
        data: data.data,
        msg: data.msg,
        code: data.code
      };
    },
    /**
     * @public
     * @description 问券调查
     */
    async getQuestionnairePageList(options = { pagination: {} }) {
      const { pagination } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/getQuestionnairePageList`, {
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: questionListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async getQuestionnaireById(id) {
      if (!id) return { records: null, $$rawData: null };
      const res = await http.get(`/${CMSGATEWAY}/api/getQuestionnaireById/${id}`);
      const data = res.data.data;
      return {
        records: questionDetailFilter(data),
        $$rawData: data
      };
    },
    async addQuestionnaireRecord(d) {
      const res = await http.post(`/${CMSGATEWAY}/api/addQuestionnaireRecord`, d);
      const data = res.data;
      return {
        data: data.data,
        msg: data.msg
      };
    },
    // 获取问卷结果
    async getQuestionnaireResultInfo(options) {
      const res = await http.post(`/${CMSGATEWAY}/api/getQuestionnaireResultInfo`, {
        questionnaireId: options.id
      });
      const data = res.data.data;
      return {
        records: questionDetailFilter(data),
        $$rawData: data
      };
    },
    // 获取访问数量
    async getSiteVisitCount() {
      const res = await http.post(`/${CMSGATEWAY}/api/getSiteVisitCount`);
      const data = res.data;
      return data;
    }
  };
  return { ...baseProvider, ...enhanceProvider };
}

export default dataProvider;

import React, { useEffect, useState } from 'react';
import './index.scss';
import '../../styles/form.scss';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { validator } from '../../../../utils/platform';
import * as yup from 'yup';
import Layer from '../../common/Layer';
import PersonalNav from './PersonalNav';
/** 基本资料 */
const Baseinfo = (props) => {
  const loginName = sessionStorage.getItem('loginName');
  const [personalInfo, setPersonalInfo] = useState({});
  const [resMessage, setResMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  // 保存事件
  const toHandleSubmit = (values) => {
    let obj = {
      visitorName: values.visitorName ? values.visitorName : '', // 真实姓名，
      documentType: 'DAM0101', // (这个写死，代表证件类型是身份证)
      documentNumber: values.documentNumber ? values.documentNumber : '', // 身份证号码
      email: values.email ? values.email : '', // 邮箱
      gender: values.gender, // 性别，会根据身份证号自动能判断
      occupation: values.occupation ? values.occupation : '' // 职业
    };

    setResMessage(obj);
    setSubmitted(true);
  };

  useEffect(() => {
    let obj = {
      visitorName: '',
      documentType: 'DAM0101',
      documentNumber: '',
      email: '',
      gender: 0,
      occupation: ''
    };

    setPersonalInfo(obj);
  }, [setPersonalInfo]);

  // 校验
  const validationSchema = yup.object().shape({
    visitorName: yup
      .string()
      .trim()
      .required('请填写真实姓名！')
      .matches(validator().fullNameRegex, '不合法姓名!'),
    documentNumber: yup
      .string()
      .trim()
      .required('请填写身份证号码！')
      .test('documentNumber', '不合法的身份证号码!', (value) => {
        return (
          (personalInfo.documentNumber !== null &&
            personalInfo.documentNumber !== '' &&
            personalInfo.documentNumber === value) ||
          validator().idcardRegex(value) === true
        );
      })
  });

  return (
    <div className="baseinfo">
      <PersonalNav />
      <div className="message">
        <Layer
          containerStyle={{
            width: 260,
            height: 154
          }}
          width={500}
          visible={submitted}
          onClose={() => {
            setSubmitted(false);
          }}
        >
          {resMessage.code === 200 ? (
            <p>修改成功</p>
          ) : (
            <p>{resMessage.msg ? resMessage.msg : '请重新登录！'}</p>
          )}
        </Layer>
        <div className="mainform">
          <Formik
            enableReinitialize={personalInfo}
            initialValues={personalInfo}
            validationSchema={validationSchema}
            onSubmit={(values, action) => {
              toHandleSubmit(values);
            }}
          >
            {({ handleSubmit, setFieldValue, values, errors }) => (
              <Form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <span>
                      <font className="must">*</font>联系电话
                    </span>
                    <Field
                      name="phoneNumber"
                      type="text"
                      id="loginname1"
                      value={loginName}
                      disabled={true}
                      style={{ backgroundColor: '#eaeaea' }}
                    />
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>真实姓名
                    </span>
                    <Field
                      type="text"
                      name="visitorName"
                      value={values.visitorName}
                      id="visitorName"
                      onChange={(e) => setFieldValue('visitorName', e.currentTarget.value)}
                    />
                    <span className="tip error">
                      <ErrorMessage name="visitorName" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>身份证号码
                    </span>
                    <Field name="documentNumber" type="text" id="documentNumber" />
                    <span className="tip error">
                      <ErrorMessage name="documentNumber" />
                    </span>
                  </li>
                  <li>
                    <span>电子邮箱</span>
                    <Field name="email" type="text" id="email" />
                    <span className="tip error">
                      <ErrorMessage name="email" />
                    </span>
                  </li>
                  <li>
                    <span>性别</span>
                    <div className="box radiobox">
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          checked={!values.gender}
                          // defaultChecked={personalInfo && !personalInfo.gender}
                          onChange={() => setFieldValue('gender', false)}
                        />
                        男
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          checked={values.gender}
                          // defaultChecked={personalInfo && personalInfo.gender}
                          onChange={() => setFieldValue('gender', true)}
                        />
                        女
                      </label>
                    </div>
                  </li>
                  <li>
                    <span>职业</span>
                    <Field name="occupation" type="text" id="occupation" />
                  </li>
                </ul>
                <div className="tc">
                  <button className="commonbtn btn" id="save" type="submit">
                    保存
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default function () {
  return <Baseinfo />;
}

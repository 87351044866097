import React from 'react';
import './index.scss';
import '../../styles/form.scss';
import { NavLink } from 'react-router-dom';

/* eslint-disable */
/** 导航 */
const PersonalNav = () => {
  return (
    <div className="common_secmenu">
      <h3>
        <i className="iconfont iconfeiyitiandi" />
        个人中心
      </h3>
      <ul>
        <li>
          <NavLink to="/PersonalCenter/Baseinfo" exact>
            基本资料
          </NavLink>
        </li>
        <li>
          <NavLink to="/PersonalCenter/UpdatePassword" exact>
            密码修改
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default PersonalNav;

import React, { Component } from 'react';
import './style.scss';
// import NoMatch from '../NoMatch';
import { Redirect } from 'react-router';
export default class ErrorCatch extends Component {
  state = { error: false, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }
  render() {
    const { error } = this.state;

    return error ? <Redirect to="/" /> : this.props.children;
  }
}

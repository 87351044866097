import React from 'react';
import './index.scss';
export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      {/* <div className="logo" /> */}
      <div className="bottom">
        <p>
          &copy; 2010-{year} {window.mainTitle} {window.copy}
        </p>
      </div>
    </div>
  );
}

/**
 * @file   内页banner图
 * @create lixiang 2020/10/19
 * @update
 */

import React from 'react';
import banner from '@/assets/images/desktop/common/banner.jpg';
import Breadcrumb from '@/app/components/Breadcrumb';
import MyLink from '../../../components/MyLink';
import { Link } from 'react-router-dom';
import { GoBack } from './GoBack';
// 内页banner图
function InnerBanner({ title, imgSrc, routesMap, pathname }) {
  const path = pathname?.split('/');
  return (
    <div className="inner-banner">
      <img src={imgSrc ? window.ENV_IMG_PREURL + imgSrc : banner} alt="" className="imgmain" />
      <div className="allcenter">
        {path[1] !== 'PersonalCenter' ? (
          <Link to={`/${path[1]}`} className="fsname">
            {title}
          </Link>
        ) : (
          <span className="fsname">{title}</span>
        )}

        {Object.keys(routesMap).length > 0 &&
          routesMap &&
          routesMap[path[1]]?.children.length > 0 &&
          path[1] !== 'PersonalCenter' && (
            <ul className="menu">
              {routesMap[path[1]].children?.map((item) => {
                return (
                  item.isVisible && (
                    <li key={item.id} className={item.route === path[2] ? 'active' : ''}>
                      <MyLink
                        to={`/${path[1]}/${item.route}`}
                        linkUrl={item.url === '/' ? '' : item.url}
                      >
                        {item.name}
                      </MyLink>
                    </li>
                  )
                );
              })}
            </ul>
          )}
      </div>
      <Breadcrumb />
      <GoBack />
    </div>
  );
}

export default InnerBanner;

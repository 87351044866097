/* eslint-disable import/first */
/**
 * @file   总页面
 * @create
 * @update lixiang 2020/09/04 头部增加是否首页判断
 */
Object.assign = require('object-assign');
import 'core-js/es/promise';
import 'core-js/es/array/find';
import 'core-js/es/array/find-index';
import 'regenerator-runtime/runtime.js';
// import 'core-js';
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { PortalSite, useRoutesMap } from '@portal-site/core';
import { routesMap } from '@portal-site/data';
import { createBrowserHistory } from 'history';
import { PortalUIProvider } from '@portal-site/components';
import { useChannel } from '../components/Hooks';
import Header from './common/Header';
import Footer from './common/Footer';
import InnerBanner from './common/Header/InnerBanner';
import { pcRoutes } from './routes';
import 'normalize.css';
import ErrorCatch from '../components/ErrorCatch';
import NoMatch from '../components/NoMatch';
import ClsNameFix from '../components/ClsNameFix';
import { dataProvider } from './service/dataProvider';
import defaultImg from '@/assets/images/default.jpg';
import SearchPage from './common/Search';
const ENV_IMG_PREURL = window.ENV_IMG_PREURL;

const App = ({ children }) => {
  return (
    <PortalSite
      models={[routesMap]}
      dataProvider={dataProvider}
      history={createBrowserHistory()}
      root="cn"
    >
      <PortalUIProvider
        value={{
          assetsPrefix: ENV_IMG_PREURL,
          pictureFallback: defaultImg
        }}
      >
        <ErrorCatch>
          <ClsNameFix>
            <Page />
          </ClsNameFix>
        </ErrorCatch>
      </PortalUIProvider>
    </PortalSite>
  );
};
const Page = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { pathnameArray, channel } = useChannel();
  const routes = useRoutesMap().flatToObject();
  const channelNo = channel?.route || pathname.split('/')[pathname.split('/').length - 1];
  // 是否首页
  const isIndex = pathname === '/' || pathname === '/Home';
  const isSearch = pathname === '/Search';

  return (
    <>
      {isSearch ? (
        <SearchPage />
      ) : (
        <>
          {!isIndex && <Header />}
          <div className={channelNo.toLowerCase() + '-body'}>
            {!isIndex && (
              <InnerBanner
                routesMap={routes}
                pathname={pathname}
                title={routes[pathnameArray[0]]?.name}
                imgSrc={routes[pathnameArray[0]]?.imgSrc}
              />
            )}
            <div className={isIndex ? 'index-cont' : 'inner-cont'}>
              <div className={isIndex ? '' : 'allcenter'}>
                <Switch>
                  {pcRoutes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  })}
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};
export default App;

/* eslint-disable complexity */
/**
 * @file   搜索列表
 * @create zhangchen 2020/11/30
 * @update zhangchen 2020/11/30 调整搜索内容
 */
import React from 'react';
import cls from 'classnames';
import MyLink from '../../../components/MyLink';
import { useRoutesMap } from '@portal-site/core';
import { Picture, Skeleton } from '@portal-site/components';
import { delHtmlTagEspan } from '@/utils';
import './style.scss';
function SearchList(props) {
  const { list, channelList, isMobile, loading, searchwords } = props;
  const routes = useRoutesMap().flatToObject();
  const allChannel = {};
  channelList &&
    Object.values(channelList).forEach((item) => {
      allChannel[item.route] = item;
    });
  // 创建包含变量的正则表达式，使用 g 表示全局匹配
  const regex = new RegExp(searchwords, 'g');
  return (
    <Skeleton
      loading={loading}
      className="skeleton-search-list"
      render={() => {
        return [1, 2, 3, 4, 5].map((item) => (
          <div key={item} className="item">
            <Skeleton.Avatar shape="rect" />
            <div className="r">
              <Skeleton.Title width="60%" />
              <Skeleton.Line width="100%" />
              <Skeleton.Line width="100%" />
              <Skeleton.Line width="80%" />
            </div>
          </div>
        ));
      }}
    >
      <ul className={cls('searchlist', { mobile: isMobile })}>
        {list.map((item, index) => {
          let showdetailno = []; // 不进入详情页展示的
          let toUrl;
          if (showdetailno.includes(item.channelNo)) {
            toUrl = allChannel[item.channelNo] ? `${allChannel[item.channelNo].path}` : '';
          } else {
            switch (item.channelNo) {
              default:
                toUrl = allChannel[item.channelNo]
                  ? `${allChannel[item.channelNo].path}/${item.id}`
                  : '';
                break;
            }
          }
          return (
            <li key={index} className="item">
              <MyLink
                chooselink={item.channelNo === 'ExcellentCol'} // 藏品有链接的也先跳转详情页
                linkUrl={item.linkUrl}
                to={toUrl}
                target={isMobile ? '_self' : '_blank'}
                className="link"
              >
                {item.imgSrc ? <Picture source={item.imgSrc} /> : null}
                <div className="cont">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: `【${routes[item.channelNo]?.name}】
                    ${item.title.replace(regex, `<span>${searchwords}</span>`)}`
                    }}
                  />
                  <div
                    className="detail"
                    dangerouslySetInnerHTML={{
                      __html: delHtmlTagEspan(
                        item.abstract ? item.abstract : item.content,
                        200
                      )?.replace(regex, '<span>' + searchwords + '</span>')
                    }}
                  />
                </div>
              </MyLink>
            </li>
          );
        })}
      </ul>
    </Skeleton>
  );
}

export default SearchList;

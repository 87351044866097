/**
 * @file   数据读取
 * @create lixiang 2020/10/13
 * @update lixiang 2020/10/13 所有的接口加上portalId
 */
import service from './index';
import { mapDataToRoute, cmsDataProvider, RootRouteMap } from '@portal-site/data';
import { http, CMSGATEWAY } from './http';
export const dataProvider = {
  ...cmsDataProvider(http, ''),
  ...service(http),
  ...{
    queryRoutes: function () {
      const isEn = window.isEn;
      const endPoint = isEn
        ? `/${CMSGATEWAY}/api/queryAllChannel/EN`
        : `/${CMSGATEWAY}/api/queryAllChannel/CN`;
      return new Promise((resolve, reject) => {
        http
          .get(endPoint)
          .then((res) => {
            const root = new RootRouteMap();
            const routeMaps = res.data.data.records.map((r) => {
              return mapDataToRoute(r, root);
            });
            root.children = routeMaps;
            resolve(root);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  }
};

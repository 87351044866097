/* react/prop-types: 0 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const isWap = /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
  navigator.userAgent
);

class ClsNameFix extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    if (pathname === '/' || pathname === '/Home' || pathname === '/EN_Home') {
      document.body.classList.remove('innerbody');
      document.body.classList.add('indexbody');
    } else {
      document.body.classList.remove('indexbody');
      document.body.classList.add('innerbody');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { pathname } = this.props.location;
      if (pathname === '/' || pathname === '/Home' || pathname === '/EN_Home') {
        document.body.classList.remove('innerbody');
        document.body.classList.add('indexbody');
      } else {
        document.body.classList.remove('indexbody');
        document.body.classList.add('innerbody');
      }
    }

    if (isWap) {
      document.body.classList.add('wapbody');
    } else {
      document.body.classList.remove('wapbody');
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ClsNameFix);

/**
 * @file   网站头部
 * @create
 * @update
 */
import React, { useRef, useState, useEffect } from 'react';
import './index.scss';
import { Link, useLocation } from 'react-router-dom';
import { useRoutesMap } from '@portal-site/core';
import { Nav } from './Nav';

// 栏目菜单
const Headerbox = () => {
  const menu = useRef();
  const [active, setState] = useState(false);
  const routes = useRoutesMap();
  useEffect(() => {
    // 如果有值
    if (menu.current) {
      const li = menu.current.getElementsByClassName('portal-nav-firstmenu-li');
      for (let index = 0; index < li.length; index++) {
        // 当没有二级栏目时，不显示二级栏目背景条
        if (li[index].getElementsByTagName('li').length > 0) {
          li[index].onmouseover = function () {
            setState(true);
          };
          li[index].onmouseout = function () {
            setState(false);
          };
        } else {
          li[index].className = 'css-grjzz9 portal-nav-firstmenu-li nochild';
          li[index].onmouseover = function () {
            setState(false);
          };
        }
      }
    }
  }, [routes.children.length]);
  const { pathname } = useLocation();
  const token = sessionStorage.getItem('token');
  return (
    <>
      <div className="allcenter" ref={menu}>
        <Nav />
        <Link to="/">
          <div className="logo" />
        </Link>
      </div>
      <ul className="quickmenu">
        {token ? (
          <li className="login">
            <Link to="/PersonalCenter/Baseinfo">个人中心</Link>|
            <Link
              onClick={() => {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('loginName');
              }}
              to="/"
            >
              注销
            </Link>
          </li>
        ) : (
          <li className="login">
            <Link to={`/PersonalCenter/Logins?backUrl=${pathname}`}>登录</Link>|
            <Link to={`/PersonalCenter/Register?backUrl=${pathname}`}>注册</Link>
          </li>
        )}
        <li className="search">
          <Link to="/Search">
            <i className="iconfont icon-sousuo" />
          </Link>
        </li>
      </ul>
      <div className={active ? 'secmenu-bg active' : 'secmenu-bg'} />
    </>
  );
};

export default function () {
  return (
    <header className="mainheader">
      <Headerbox />
    </header>
  );
}

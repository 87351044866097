// import idcard from 'idcard';

/**
 * 获取url参数
 *
 * @export
 * @param {*} search
 * @returns
 */
export function parseSearch(search) {
  return new URLSearchParams(search);
}

/**
 * 初始化页码
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getInitPage(params) {
  let initPage = 1;
  const page = Number(params.get('page'));
  !page ? (initPage = 1) : (initPage = page);
  return initPage;
}

/**
 * 返回栏目列表
 *
 * @export
 * @param {*} pathname
 * @returns
 */
export function getChannelNo(pathname) {
  const lang = getLanguage();
  const pathnameArray = pathname.split('/').filter((item) => item !== '');

  pathnameArray.unshift('');
  const object = pathnameArray.reduce((acc, cur, index) => {
    acc[index] = lang && cur ? `${cur}-${lang}` : cur;
    return acc;
  }, {});
  object.array = pathnameArray;
  object.prePath = pathnameArray.join('/');
  return object;
}

export function getLanguage() {
  let lang = {};
  const href = window.location.href;
  // 英文版
  if (href.includes('en') || href.includes('phoneen') || href.includes('EN-Home')) {
    lang.en = true;
    lang.enPrefix = 'EN_';
  }

  return lang;
}

export function dealChannelNo(lang) {
  return (channelNo) => {
    if (lang) {
      return channelNo.split('-')[0];
    }
    return channelNo;
  };
}

/* export function getPlatform() {
  //   const isWap = window.location.pathname.includes('wap');
  const platform = new URLSearchParams(window.location.search).get('platform');
  //   const p = ['wx'].includes(platform) ? platform : isWap ? 'wap' : '';
  const p = ['wx'].includes(platform) ? platform : '';
  return p;
}
export const searchPlatform = getPlatform() ? `?platform=${getPlatform()}` : '';
export const searchLang = getLanguage() ? `?lang=${getLanguage()}` : '';

 */
export function getPlatform(type) {
  //   const isWap = window.location.pathname.includes('wap');
  // const platform = new URLSearchParams(window.location.search).get('platform');
  const platform = window.location.href.includes('platform=wx');
  // const p = ['wx'].includes(platform) ? platform : '';
  return platform;
}

export const searchPlatform = getPlatform() ? `?platform=wx` : '';

export function validator() {
  return {
    idCardRegex:
      /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    phoneNumRegex:
      /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/, // 中国大陆手机号匹配
    userNameRegex: /^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/, // 用户名
    pwdRegex: /^[\w_]{6,16}$/, // 6-16位  字母数字下划线
    fullNameRegex: /^[\u4e00-\u9fa5]{2,5}(?:·[\u4e00-\u9fa5]+)?$/, // 汉字姓名正则
    // idcardRegex: (IdNum) => idcard.verify(IdNum), // 身份证
    contentRegex: /^(<\s*\/?\s*[a-zA-z_]([^>]*?["][^"]*["])*[^>"]*>)/g, // 留言内容
    chineseRegex: /[\u4e00-\u9fa5]/, // 中文
    sCEURegex: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/, // 中文，英文字母和数字及下划线,
    emailRegex:
      // eslint-disable-next-line no-control-regex
      /^((([a-z]|\d|[!#$%&'\\*+\-\\/=?\\^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\\$%&'\\*+\-/=?\\^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
  };
}

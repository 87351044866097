import React, { useState } from 'react';
import './index.scss';
import '../../styles/form.scss';
// import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { http } from '../../service/http';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Layer from '../../common/Layer';
import PersonalNav from './PersonalNav';

/** 密码修改 */
const Baseinfo = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [resMessage, setResMessage] = useState({});
  const validationSchema = yup.object().shape({
    loginPwd: yup
      .string()
      .trim()
      .required('请输入旧密码！')
      .matches(/^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/, '不合法密码！'),
    newLoginPwd: yup
      .string()
      .trim()
      .required('请输入新密码！')
      .matches(/^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/, '不合法密码！'),
    loginPwdAgain: yup
      .string()
      .trim()
      .required('请确认新密码！')
      .oneOf([yup.ref('newLoginPwd')], '与新密码不同！')
  });
  const toHandleSubmit = (values) => {
    let obj = {
      loginPwd: values.loginPwd,
      newLoginPwd: values.newLoginPwd
    };

    http.post('/japi/sw-dam-xn/visitor/updatePwdById', obj).then((res) => {
      setResMessage(res.data);
      setSubmitted(true);
    });
  };
  return (
    <div className="baseinfo">
      <PersonalNav />
      <div className="message">
        <Layer
          containerStyle={{
            width: 260,
            height: 154
          }}
          width={500}
          visible={submitted}
          onClose={() => {
            setSubmitted(false);
          }}
        >
          {resMessage.code === 200 ? <p>修改成功</p> : <p>修改失败</p>}
        </Layer>
        <div className="mainform">
          <Formik
            initialValues={{
              newLoginPwd: '',
              loginPwd: '',
              loginPwdAgain: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, action) => {
              toHandleSubmit(values);
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <span>
                      <font className="must">*</font>旧密码
                    </span>
                    <Field name="loginPwd" type="password" id="pwd1" />
                    <span className="tip error">
                      <ErrorMessage name="loginPwd" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>新密码
                    </span>
                    <Field name="newLoginPwd" type="password" id="pwd2" />
                    <span className="tip error">
                      <ErrorMessage name="newLoginPwd" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>确认密码
                    </span>
                    <Field name="loginPwdAgain" type="password" id="pwd3" />
                    <span className="tip error">
                      <ErrorMessage name="loginPwdAgain" />
                    </span>
                  </li>
                </ul>
                <div className="tc">
                  <button className="commonbtn btn" id="save" type="submit">
                    保存
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default function () {
  return <Baseinfo />;
}

/**
 * @file   返回
 * @create lixiang 2023/09/28
 * @update
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import './goback.scss';
export const GoBack = () => {
  const isEn = window.isEn;
  const history = useHistory();
  return history.length > 1 ? (
    <div
      className="goback"
      onClick={() => {
        history.goBack();
      }}
    >
      <span>«</span>
      {isEn ? 'Back' : '返回'}
    </div>
  ) : (
    <div
      className="goback"
      onClick={() => {
        window.close();
      }}
    >
      {isEn ? 'Close' : '关闭'}
      <span>x</span>
    </div>
  );
};

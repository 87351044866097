import React, { useMemo, useCallback } from 'react';
import './index.scss';
import { NavLink } from 'react-router-dom';
import { Nav as NavComp } from '@portal-site/components';
import { useRoutesMap } from '@portal-site/core';

// 栏目菜单
export function Nav() {
  const testReg = /^http[s]{0,1}:/;
  // 检测当前路径是否为完整图片路径
  function isUrl(src) {
    return testReg.test(src);
  }
  const routes = useRoutesMap();
  const cl = useCallback((match, location, to) => {
    return location.pathname.split('/').includes(to);
  }, []);
  // 隐藏后台设置为不显示的栏目
  const exclude = useMemo(() => {
    const flat = routes.flat();
    return flat.filter((i) => !i.isVisible).map((i) => i.route);
  }, [routes]);

  const renderNavLink = (routes) => {
    if (routes.url && isUrl(routes.url)) {
      return (
        <a href={routes.url} target="_blank" rel="noopener noreferrer">
          {routes.name}
        </a>
      );
    }
    return routes.url !== '/' && routes.url ? (
      <NavLink to={routes.url} target="_blank">
        {routes.name}
      </NavLink>
    ) : (
      <NavLink to={routes.path}>{routes.name}</NavLink>
    );
  };
  return <NavComp routes={routes} exclude={exclude} isActive={cl} renderNavLink={renderNavLink} />;
}

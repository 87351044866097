// import { useEffect, useMemo, useState } from 'react';
import { useMemo } from 'react';
import { useRoutesMap } from '@portal-site/core';
import { useLocation } from 'react-router';

// 判断是否为数字
// const isNum = (n) => !Number.isNaN(Number(n));

// const useChannel = function () {
//   const routes = useRoutesMap();

//   const { pathname } = useLocation();

//   const memoName = useMemo(() => {
//     const pathnameArray = pathname.split('/').filter((item) => item !== '' && item !== 'Home');

//     // pathnameArray.unshift('');
//     // 获取最深层栏目
//     const length = pathnameArray.length;
//     const lastIndex = length - 1 || 0;
//     const last = pathnameArray[lastIndex];
//     const isDetail = last?.length >= 32 || isNum(last);
//     const r = routes.flatToObject();

//     // 可能有2个32哦 /id/groupId
//     const l = pathnameArray.filter((i) => i.length >= 32 || isNum(i)).length;
//     const current = isDetail ? lastIndex - l : lastIndex;
//     const channel = r[pathnameArray[current]];

//     return { channel: channel, channelName: channel?.name, pathnameArray: pathnameArray, pathname };
//   }, [routes, pathname]);

//   return memoName;
// };

/**
 * @public
 * @description 递归获取当前栏目信息
 * @param {string[]} pathnameArray - 路由路径数组
 * @param {object} routesObj - 路由对象
 * @returns {object} channel - 当前栏目 信息
 */
const getChannel = (pathnameArray, routesObj) => {
  // 如果只有一条栏目信息
  if (pathnameArray.length <= 1) {
    return routesObj[pathnameArray[0]] || {};
  }
  const length = pathnameArray.length - 1 || 0;
  const channel = routesObj?.[pathnameArray?.[length]];
  return channel || getChannel(pathnameArray.slice(0, pathnameArray.length - 1), routesObj);
};

const useChannel = function () {
  const routes = useRoutesMap();
  const { pathname } = useLocation();
  const pathnameArray = pathname.split('/').filter((item) => item !== '' && item !== 'Home');
  const channel = useMemo(() => {
    if (routes.children?.length) {
      if (pathnameArray.includes('Message')) {
        return { name: '观众留言' };
      }
      const routesObj = routes.flatToObject();
      const _channel = getChannel(pathnameArray, routesObj);
      if (_channel && Object.keys(_channel)?.length) {
        return _channel;
      }
      return {};
    }
  }, [routes, pathnameArray]);
  return { channel, channelName: channel?.name, pathnameArray, pathname };
};
export default useChannel;

import React from 'react';
import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';

const inBrowser = typeof window !== 'undefined';
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isIE9 = UA && UA.indexOf('msie 9.0') > 0;
const ENTER = 'enter';
const LEAVE = 'leave';
let timer = 0;

function clearTimer() {
  clearTimeout(timer);
}
export default class Layer extends React.Component {
  static defaultProps = {
    maskClosable: true,
    duration: '0.3s',
    clsFix: 'aimer',
    animation: 'scale',
    width: 300,
    visible: false,
    delay: 0,
    maskable: true
  };

  // static propTypes = {
  //     maskClosable: PropTypes.bool,
  //     onClose: PropTypes.func.isRequired,
  //     duration: PropTypes.string,
  //     animation: PropTypes.string,
  //     clsFix: PropTypes.string,
  //     visible: PropTypes.bool,
  //     delay: PropTypes.number,
  //     width: PropTypes.number,
  //     maskable: PropTypes.bool,
  // };

  state = {
    visible: false,
    status: LEAVE
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.visible && !state.visible) {
      return {
        visible: true,
        status: ENTER
      };
    }
    if (!nextProps.visible && state.visible) {
      // ie9 不支持animnationevent
      const newState = isIE9 ? { visible: false } : { status: LEAVE };
      return newState;
    }
    return null;
  }

  componentDidMount() {
    this.closeAsync();
  }

  componentDidUpdate() {
    if (this.props.visible) {
      this.closeAsync();
      // document.body.style.overflow = 'hidden';
    } else {
      clearTimer();
      document.body.style.overflow = 'auto';
    }
  }

  componentWillUnmount() {
    clearTimer();
  }

  closeAsync() {
    clearTimer();
    if (this.props.delay) {
      timer = setTimeout(() => {
        this.props.onClose();
      }, this.props.delay);
    }
  }

  onLeaveAnimationEnd = () => {
    const { status } = this.state;

    if (status === LEAVE) {
      this.setState({ visible: false });
    }
  };

  render() {
    const {
      maskClosable,
      onClose,
      children,
      duration,
      clsFix,
      animation,
      width,
      containerStyle,
      destroyOnClose,
      maskStyle,
      maskable
    } = this.props;

    const { visible, status } = this.state;
    const onMaskClick = maskClosable ? onClose : null;
    const _contentStyle = {
      animationDuration: duration,
      WebkitAnimationDuration: duration,
      width,
      ...containerStyle
    };

    return ReactDOM.createPortal(
      <div
        className="aimer-layer"
        // style={{ display: visible ? 'block' : 'none' }}
      >
        {visible && (
          <>
            {maskable && (
              <div className="aimer-layer-shade" onClick={onMaskClick} style={maskStyle} />
            )}
            <div className="aimer-layer-main">
              <div className="aimer-layer-wrap">
                <div
                  onAnimationEnd={this.onLeaveAnimationEnd}
                  style={_contentStyle}
                  className={`${clsFix}-layer-container anim-${animation}-${status}`}
                >
                  {visible || !destroyOnClose ? children : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>,
      document.body
    );
  }
}

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from 'react';
import './index.scss';
import '../../styles/form.scss';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Layer from '../../common/Layer';
import { useHistory, useLocation } from 'react-router-dom';

const Register = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [leaveTime, setLeaveTime] = useState(3);
  const [resMessage, setResMessage] = useState({});
  const location = useLocation();
  const backUrl = location.search && location.search.split('=').reverse()[0];
  let timeout = 0;
  let leave = 3;

  // 计时
  const handleLeave = () => {
    if (leave === 0) {
      clearTimeout(timeout);
      if (location.search) {
        props.his.push(backUrl);
      } else {
        props.his.push('/PersonalCenter/Logins');
      }
      return;
    }
    timeout = setTimeout(() => {
      leave = leave - 1;
      setLeaveTime(leave);
      handleLeave();
    }, 1000);
  };

  /** 提交注册数据 */
  const toHandleSubmit = (values) => {
    let mdsPassword = values.loginPwd;
    delete values.loginPwdAgain; // 删除重复输入的密码
    const tempValue = { ...values };
    tempValue.loginPwd = mdsPassword;

    setResMessage({ code: 200, msg: '注册成功' });

    const token = values.phoneNumber + '123';
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('loginName', values.phoneNumber);
    handleLeave();

    setSubmitted(true);
  };

  // 验证输入信息
  const validationSchema = yup.object().shape({
    loginPwd: yup
      .string()
      .trim()
      .required('请输入密码！')
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_]{5,15}$/,
        '密码以字母开头，可包含大写、小写英文字母，数字，下划线，长度为6至16位。'
      ),
    loginPwdAgain: yup
      .string()
      .trim()
      .required('请确认密码')
      .oneOf([yup.ref('loginPwd')], '与原密码不同！'),
    phoneNumber: yup
      .string()
      .required('请填写手机号！')
      .matches(/^((\+|00)86)?1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/, '不合法手机号')
  });

  const phoneNumber = useRef('');

  return (
    <div className="register">
      <h3>用户注册</h3>
      <Layer
        containerStyle={{
          width: 260,
          height: 154
        }}
        width={500}
        visible={submitted}
        onClose={() => {
          setSubmitted(false);
        }}
      >
        {resMessage.code === 200 ? (
          <p>注册成功，{leaveTime}秒后跳转...</p>
        ) : (
          <p>{resMessage.msg}</p>
        )}
      </Layer>
      <div className="mainform registerform">
        <Formik
          initialValues={{
            loginPwd: '',
            loginPwdAgain: '',
            phoneNumber: ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values, action) => {
            toHandleSubmit(values);
          }}
        >
          {({ handleSubmit, values }) => {
            phoneNumber.current = values.phoneNumber;
            return (
              <Form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <span>
                      <font className="must">*</font>手机号码：
                    </span>
                    <Field name="phoneNumber" type="text" id="phonenumber" />
                    <span className="tip error">
                      <ErrorMessage name="phoneNumber" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>密码：
                    </span>
                    <Field name="loginPwd" type="password" id="pwd1" />
                    <span className="tip error">
                      <ErrorMessage name="loginPwd" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <font className="must">*</font>确认密码：
                    </span>
                    <Field name="loginPwdAgain" type="password" id="pwd2" />
                    <span className="tip error">
                      <ErrorMessage name="loginPwdAgain" />
                    </span>
                  </li>
                </ul>
                <div className="tc">
                  <button className="commonbtn btn" id="commit" type="submit">
                    提 交
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default function () {
  const his = useHistory();
  return <Register his={his} />;
}

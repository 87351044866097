/**
 * @file
 * @create
 * @update
 * @update
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PagerConfig } from '@portal-site/pager';
import './styles/animation.scss';
// import './common/MainDetail';
import '@/styles/slick.min.css';
import '@/styles/viewer.min.css';
import '@/styles/animate.min.css';
import './styles/all.scss';
// antd ie适配
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
let pagerValue = {
  prevLabel: '',
  nextLabel: '',
  showFirst: true,
  showLast: true,
  showTotal: true,
  showJump: true,
  firstLabel: window.isEn ? 'first page' : '首页',
  lastLabel: window.isEn ? 'last page' : '末页'
};

ReactDOM.render(
  <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
    <PagerConfig.Provider value={pagerValue}>
      <App />
    </PagerConfig.Provider>
  </StyleProvider>,
  document.getElementById('app')
);

/**
 * @file   公共方法
 * @create lixiang 2020/09/14
 * @update zhangchen 2020/09/22 添加获取当前时间方法
 * @update zhangchen 2020/11/24 修改newdate()在ios上显示NaN bug
 */

/**
 * author yangming
 * @param 将带标签的html内容转换成string字符串
 * @param {string} content : 需要处理的带<>的字符串
 * @param {number} num : 需要截取的字符串长度（默认为空）
 * @param return 截取之后的字符串
 * @param createTime:2019/3/4
 *
 * @param isEn : 判断是否为英文段落截取
 */
export function htmltoStr(content, num = 10000, isEn = false) {
  if (content === null) return '';
  // eslint-disable-next-line no-param-reassign
  if (!content) content = '';
  let quot = content
    .replace(/&rdquo;/g, '”')
    .replace(/&ldquo;/g, '“')
    .replace(/&quot;/g, '"')
    .replace(/<[^>]+>|&[^>]+;/g, '')
    .trim();
  // 处理文本中的HTML标签
  const s = quot.replace(/<[^>]+>/g, '').trim();
  // s为空字符串
  if (s === '') return '';
  // 判断是否为英文段落截取
  if (isEn) {
    const str1 = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
    if (str1.length < num) return str1;
    // 截取最后一个空格前的段落（段尾保留完整的英文单词）
    const text = str1.substr(0, Number(num));
    const n = text.lastIndexOf(' ');
    return `${text.substr(0, n)}...`;
  }
  // const t = s.replace(/ /g, '');

  if (s.length < num) {
    // 如果文字数量少于num，则直接返回
    return `${s}`;
  } else {
    // 需要截断文本长度
    if (num > 0) return `${s.substr(0, Number(num))}...`;
    return `${s}...`;
  }
}

/**
 * @param 处理文本中的HTML标签
 * @param {string} str
 */
export function deleteHtmlTag(str) {
  const str1 = str && str.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  return str1;
}

/**
 * 获取html字符串中第一张图片的地址
 * @param {String} 需要处理的文本
 * @return {String} 处理的结果
 */
export function getFirstImg(content) {
  if (!content) return '';
  //   匹配图片
  const imgReg = /<img.*?(?:>|\/>)/gi;
  //   匹配src
  const srcReg = /src=['"]?([^'"]*)['"]?/i;
  const arr = content.match(imgReg);
  if (arr === null || arr.length < 0) return '';
  //   获取第一张图片的src
  const src = arr[0].match(srcReg);
  return src[1];
}

/**
 * 节流
 *
 * @export
 * @param {*} func
 * @param {number} [time=17]
 * @param {boolean} [options={ leading: true, trailing: false, context: null }]
 * @returns
 */
export function throttle(
  func,
  time = 17,
  options = { leading: true, trailing: false, context: null }
) {
  let previous = new Date(0).getTime();
  let timer;
  const _throttle = function (...args) {
    let now = new Date().getTime();
    if (!options.leading) {
      if (timer) return;
      timer = setTimeout(() => {
        timer = null;
        func.apply(options.context, args);
      }, time);
    } else if (now - previous > time) {
      func.apply(options.context, args);
      previous = now;
    } else if (options.trailing) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(options.context, args);
      }, time);
    }
  };
  _throttle.cancel = () => {
    previous = 0;
    clearTimeout(timer);
    timer = null;
  };
  return _throttle;
}

export function debounce(func, time = 16, options = { leading: true, trailing: true }) {
  let timer;
  let result;
  let context;
  let args;
  let now;
  let later = function () {
    let last = new Date().getTime() - now;
    if (last < time && last >= 0) {
      timer = setTimeout(later, time - last);
    } else {
      timer = null;
      if (!options.leading || (options.leading && options.trailing)) {
        result = func.apply(context, args);
        // 释放变量
        if (!timer) context = args = null;
      }
    }
  };
  const _debounce = function () {
    context = this;
    args = arguments;
    now = new Date().getTime();
    // 第一次调用该方法时，且immediate为true，则调用func函数
    let callNow = options.leading && !timer;
    // 第一次赋值timer
    if (!timer) timer = setTimeout(later, time);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
  _debounce.cancel = function () {
    clearTimeout(timer);
    timer = context = null;
  };
  return _debounce;
}

/**
 * 返回传入值的类型
 *
 * @export
 * @param {*} obj
 * @returns
 */
export function type(obj) {
  return Object.prototype.toString
    .call(obj)
    .replace(/^\[object (.+)\]$/, '$1')
    .toLowerCase();
}

export function browserInfo() {
  const UserAgent = navigator.userAgent.toLowerCase();
  let browser = null;

  const browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
    Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
    Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
    Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') === -1, // safari浏览器
    Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
    QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
  };
  for (let i in browserArray) {
    if (browserArray[i]) {
      browser = i;
    }
  }
  return browser;
}
export function getOperationSys() {
  let OS = 'Unknown';
  let OSArray = {};
  const UserAgent = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  OSArray.Windows = platform === 'win32' || platform === 'windows';
  OSArray.Mac =
    platform === 'mac68k' ||
    platform === 'macpcc' ||
    platform === 'macintosh' ||
    platform === 'macintel';
  OSArray.iphone = UserAgent.indexOf('iphone') > -1;
  OSArray.ipod = UserAgent.indexOf('ipod') > -1;
  OSArray.ipad = UserAgent.indexOf('ipad') > -1;
  OSArray.Android = UserAgent.indexOf('android') > -1;
  for (let i in OSArray) {
    if (OSArray[i]) {
      OS = i;
    }
  }
  return OS;
}

/**
 * 根据day获取星期
 * @export
 * @param {*} day 星期数
 * @param {*} type 类型(默认0) 0:周日 1:星期天 2:Sunday 3:Sun
 * @returns
 */
export function getWeek(day, type) {
  if (!type) {
    // eslint-disable-next-line no-param-reassign
    type = 0;
  }
  let weekday = [
    ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
  ];
  return weekday[type][day];
}

/**
 * 获取显示日期，格式为：2015年3月14日（周六）10:00-11:30
 *
 * @export
 * @param {*} StartTime 开始时间
 * @param {*} EndTime   结束时间
 * @param {*} ishasWeek 是否显示星期 默认显示
 * @returns
 */
export function showDateTime(paramStartTime, paramEndTime, ishasWeek) {
  const StartTime = paramStartTime.replace(/-/g, '/');
  const EndTime = paramEndTime.replace(/-/g, '/');

  if (StartTime === '' || EndTime === '' || StartTime === null || EndTime === null) {
    return '';
  }

  let SatrtTimeTemp = '';
  let startYear;
  let startMonth;
  let startDay;
  let startWeek;
  let startTiming;
  let endYear;
  let endMonth;
  let endDay;
  let endWeek;
  let endTiming;
  // 获取开始年份
  startYear = new Date(StartTime).getFullYear();
  // 获取开始月份
  startMonth = new Date(StartTime).getMonth() + 1;
  // 获取开始日期
  startDay = new Date(StartTime).getDate();
  // 获取星期
  startWeek = ishasWeek ? `（${getWeek(new Date(StartTime).getDay())}）` : '';
  // 获取时间
  startTiming = StartTime.split(' ')[1] ? StartTime.split(' ')[1] : '';
  // 获取截止年份
  endYear = new Date(EndTime).getFullYear();
  // 获取截止月份
  endMonth = new Date(EndTime).getMonth() + 1;
  // 获取截止日期
  endDay = new Date(EndTime).getDate();
  // 获取截至星期
  endWeek = ishasWeek ? `（${getWeek(new Date(EndTime).getDay())}）` : '';
  // 获取截至时间
  endTiming =
    EndTime.split(' ')[1] & (EndTime.split(' ')[1] !== '00:00') ? EndTime.split(' ')[1] : '';

  SatrtTimeTemp += `${startYear}年${startMonth}月${startDay}日${startWeek}${
    startTiming === '00:00' ? '' : startTiming
  }`;
  if (
    !(StartTime.split(' ')[0] === EndTime.split(' ')[0] && !startTiming) &&
    StartTime !== EndTime
  ) {
    SatrtTimeTemp += '~';
  }

  if (startYear !== endYear) {
    SatrtTimeTemp += `${endYear}年${endMonth}月${endDay}日${endWeek}${endTiming}`;
  } else {
    if (startMonth !== endMonth) {
      SatrtTimeTemp += `${endMonth}月${endDay}日${endWeek}${endTiming}`;
    } else {
      if (startDay !== endDay) {
        SatrtTimeTemp += `${endDay}日${endWeek}${endTiming}`;
      } else {
        SatrtTimeTemp += `${endTiming}`;
      }
    }
  }
  return SatrtTimeTemp;
}
export function getYearList(num) {
  let count = num ? num : 10;
  const firstYear = new Date().getFullYear();
  let year = firstYear;
  const arr = [];

  arr.push({ label: year, value: year });

  while (firstYear - year < count - 1) {
    year--;
    firstYear - year !== count - 1
      ? arr.push({ label: year, value: year })
      : arr.push({ label: year + '年及以前', value: year });
  }
  return arr;
}

export function handleYear(year, isEnd) {
  const start = !isEnd ? year + '-01-01' : '';
  const end = year + '-12-31';
  return start + '#' + end;
}

// 取推荐内容 type为字段名
export function getRecommend(data, type) {
  let isrecomm = false;
  for (let i = 0; i < data.length; i++) {
    if (data[i].flag.indexOf('n') > -1) {
      isrecomm = true;
      return type ? data[i][type] : data[i];
    }
  }
  // 如果没有推荐，则读第一条
  if (!isrecomm) {
    return type ? data[0][type] : data[0];
  }
}
// 缩略图
export function changePictureSize(path, width) {
  if (!path) return;
  // THUMB
  let pa = path.split('/');
  pa.splice(-1, 0, 'THUMB');
  let imgpath = pa.pop().split('.');
  imgpath.splice(1, 0, `_${width}@x.`);
  pa.push(imgpath.join(''));
  return pa.join('/');
}

// 水印图
export function getWaterPicture(path) {
  if (!path) return;
  // THUMB
  let pa = path.split('/');
  pa.splice(-1, 0, 'WATER');
  let imgpath = pa.pop().split('.');
  imgpath.splice(1, 0, `_IMAGE_REMARK.`);
  pa.push(imgpath.join(''));
  return pa.join('/');
}

/**
 * @public
 * @description 获取活动按钮状态
 * @param {string} reserveType - 预约类型
 * @param {number} reserveStatus - 预约状态
 * @param {number} activityStatus - 活动状态
 * @returns {object} value - 结果
 */
// eslint-disable-next-line max-params
export function getActBtnStatus(reserveType, reserveStatus, activityStatus) {
  if (reserveType === 'CMS0801') {
    return {
      show: true,
      text: '无需预约',
      unable: true
    };
  }
  if (reserveType === 'CMS0804') {
    return {
      show: true,
      text: '电话预约',
      unable: true
    };
  }
  if (activityStatus === -1) {
    return {
      show: true,
      text: '活动已结束',
      unable: true
    };
  }
  if (reserveStatus === 0) {
    return {
      show: true,
      text: '预约未开始',
      unable: true
    };
  }
  if (reserveStatus === -1) {
    return {
      show: true,
      text: '预约已结束',
      unable: true
    };
  }
  return {
    show: true,
    text: '正在预约'
  };
}

/**
 * 去除 String 中html标签 span除外
 * @param 将带标签的html内容转换成string字符串
 * @param {string} content : 需要处理的带<>的字符串
 * @param return 截取之后的字符串
 * @param createTime:2019/11/25
 *String regEx = "style=\"(.*?)\"";
 */
export function delHtmlTagEspan(str, num) {
  if (str) {
    // const imgReg = /<img.*?(?:>|\/>)/gi;
    const spanStr = str
      .replace(/<img.*?(\/>)/gi, '')
      .replace(/<(?!myh|\/myh).*?>|style="(.*?)"|&nbsp;/g, '');
    if (spanStr.length < num) {
      return spanStr;
    } else {
      let str = spanStr.substr(0, Number(num));
      const extra = str.replace(/[^<myh>]/g, '').length;
      str = spanStr.substr(0, Number(num) + Number(extra));
      const n = str.slice(-5).indexOf('<');
      let fstr;
      if (n > -1) {
        fstr = str.slice(0, str.length - 5 + n);
        const p = fstr.split('<myh').length - 1;
        const q = fstr.split('</myh>').length - 1;
        if (p > q) {
          fstr = fstr + '</myh>...';
          return fstr;
        } else {
          fstr += '...';
          return fstr;
        }
      } else {
        fstr = str + '...';
        return fstr;
      }
    }
  }
}

// 国际标准格式时间与当前时间比较大小
// 大于当前时间返回true
export function compareTime(time) {
  function utc2beijing(time) {
    // 转为正常的时间格式 年-月-日 时:分:秒
    let T_pos = time.indexOf('T');
    let Z_pos = time.indexOf('Z');
    let year_month_day = time.substr(0, T_pos);
    let hour_minute_second = time.substr(T_pos + 1, Z_pos - T_pos - 1);
    let new_datetime = year_month_day + ' ' + hour_minute_second;

    // 处理成为时间戳
    let timestamp = new Date(Date.parse(new_datetime));
    timestamp = timestamp.getTime();
    timestamp = timestamp / 1000;

    // 增加8个小时，北京时间比utc时间多八个时区
    timestamp = (timestamp + 8 * 60 * 60) * 1000;

    let now = new Date().getTime();
    return timestamp > now;
  }
  return utc2beijing(time);
}

// utc时间转北京
export function utcChange(time) {
  function timestampToTime(timestamp) {
    let date = new Date(timestamp * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  }
  function beijing(time) {
    // 转为正常的时间格式 年-月-日 时:分:秒
    let T_pos = time.indexOf('T');
    let Z_pos = time.indexOf('Z');
    let year_month_day = time.substr(0, T_pos);
    let hour_minute_second = time.substr(T_pos + 1, Z_pos - T_pos - 1);
    let new_datetime = year_month_day + ' ' + hour_minute_second;

    // 处理成为时间戳
    let timestamp = new Date(Date.parse(new_datetime));
    timestamp = timestamp.getTime();
    timestamp = timestamp / 1000;

    // 增加8个小时，北京时间比utc时间多八个时区
    timestamp = timestamp + 8 * 60 * 60;
    // eslint-disable-next-line radix
    let beijing_datetime = timestampToTime(timestamp);
    return beijing_datetime;
  }
  return beijing(time);
}

// 获取当前时间
export function getNowFormatDate() {
  let date = new Date();
  let seperator1 = '-'; // 格式分隔符
  let year = date.getFullYear(); // 获取完整的年份(4位)
  let month = date.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
  let strDate = date.getDate(); // 获取当前日(1-31)
  if (month >= 1 && month <= 9) month = '0' + month; // 如果月份是个位数，在前面补0
  if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate; // 如果日是个位数，在前面补0
  let currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

// 获取栏目下所有子栏目
export function serverArray(arr) {
  let newArr = [];
  for (let item = 0; item < arr?.length; item++) {
    if (arr?.[item]?.route) {
      newArr.push(arr?.[item]?.route); // push数据
    }
    if (arr?.[item]?.children) {
      let arr1 = serverArray(arr?.[item]?.children);
      newArr = [...newArr, ...arr1];
    }
  }
  return newArr;
}
// 获取地址栏参数
export function GetQueryString(name, search) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = search ? search.substr(1).match(reg) : window.location.search.substr(1).match(reg);
  if (r !== null) return decodeURI(r[2]);
  return null;
}
// 计算字体大小,返回数值大小
export function calculateFontSize() {
  let baseSize = 10; // 基数
  let baseWidth = 375; // 量取值大小
  // winw = document.documentElement.clientWidth < document.documentElement.clientHeight ? document.documentElement.clientWidth : document.documentElement.clientHeight,
  let winw = document.documentElement.clientWidth;
  let fontSize = ((winw / baseWidth) * baseSize).toFixed(2);
  document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px';
  return fontSize;
}
